import React from 'react'

function LoadingScreen() {
  return (
    <div className="loading-screen">
    <div className="loading-spinner"></div>
  </div>
  )
}

export default LoadingScreen